<template>
  <div class="mainPage">
    <!--面包屑导航-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据看版</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片内容区域-->
    <!-- <h2>数据看版</h2> -->

    <el-card style="background: #f1f5f8">
      <div class="index">
        <div class="div">
          <img src="../../assets/img/dayTransactionQuantity.png" alt="" />
          <div>
            <p class="divtite">日成交量</p>
            <p class="divdata">
              <animate-number
                ref="totalOrderCountNum"
                from="0"
                :to="body.todayOrderCount"
                :key="body.todayOrderCount"
                duration="2000"
              ></animate-number>
            </p>
            <p class="divSubtite">总成交量 {{ body.orderCount }}</p>
          </div>
        </div>

        <div class="div">
          <img src="../../assets/img/dayVisitQuantity.png" alt="" />

          <div>
            <p class="divtite">日访问量</p>

            <p class="divdata">
              <animate-number
                from="0"
                ref="transactionConversionCate"
                :to="body.todayVisit"
                :key="body.todayVisit"
                duration="2000"
              ></animate-number>
              <!-- <animate-number from="0"  ref="transactionConversionCate" :to="body.visitNumber " :key="body.visitNumber"  duration="2000"></animate-number>  -->
            </p>
            <p class="divSubtite">总访问量 {{ body.visit }}</p>

            <!-- <p class="divbottom" >
                今日访问量  <animate-number from="0" ref="todayTransactionConversionCate" :to="body.todayVisitNumber " :key="body.todayVisitNumber"  duration="2000"></animate-number> 
              </p> -->
          </div>
        </div>
        <div class="div">
          <img src="../../assets/img/dayTransactionAmount.png" alt="" />

          <div>
            <p class="divtite">日成交金额</p>
            <p class="divdata">
              <animate-number
                ref="reNum"
                from="0"
                :to="body.todayOrderMoneyCount"
                :key="body.todayOrderMoneyCount"
                duration="2000"
              ></animate-number>
              <!-- <animate-number ref="reNum" from="0" :to="body.orderCount" :key="body.orderCount" duration="2000"></animate-number> -->
            </p>
            <p class="divSubtite">总成交金额 {{ body.orderMoneyCount }}</p>
          </div>
        </div>
        <div class="div">
          <img src="../../assets/img/dayAccountAdd.png" alt="" />

          <div>
            <p class="divtite">日用户新增</p>
            <p class="divdata">
              <animate-number
                ref="addNewUsers"
                from="0"
                :to="body.todayCount"
                :key="body.todayCount"
                duration="2000"
              ></animate-number>
              <!-- <animate-number ref="addNewUsers" from="0" :to="body.addNewUsers" :key="body.addNewUsers"  duration="2000"></animate-number> -->
            </p>
            <p class="divSubtite">总用户 {{ body.total }}</p>

            <!-- <p class="divbottom" >
                日环比<i style="color:#8AD762 ;" class="el-icon-caret-top"></i> <animate-number ref="proportionAddNewUsers" from="0" :to="body.proportionAddNewUsers" :key="body.proportionAddNewUsers"  duration="2000"></animate-number> %
              </p> -->
          </div>
        </div>
      </div>
      <div class="indexy">
        <div class="dis">
          <!-- <div class="indexytite"><p @click="onindex(0)">成交量</p><p @click="onindex(73)">访问量</p></div> -->
          <!-- <div class="indexytite" style="justify-content:right ;margin-right: 24px;"><div  style="display:flex;justify-content: left;align-items: center;">
            <p :style="date==0?'font-weight: 600;':''" @click="ondatecenter(0)">今日</p><p :style="date==1?'font-weight: 600;':''" @click="ondatecenter(1)">本周</p><p :style="date==2?'font-weight: 600;':''" @click="ondatecenter(2)">本周</p><p :style="date==3?'font-weight: 600;':''" @click="ondatecenter(3)">本月</p><p :style="date==4?'font-weight: 600;':''" @click="ondatecenter(4)">全年</p></div> 
            <el-date-picker
              size="mini"
              style="width: 300px;"
              v-model="CheckInTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder=""
              end-placeholder=""
              @change="timeHandler">
          </el-date-picker>
        </div> -->
        </div>
        <!-- <div class="pitch">
            <div :style="{left:left+'px'}" ></div>
        </div> -->
        <div class="indexyone" style="cursor: pointer">
          <div class="centerLeft">
            <div class="filterItems">
              <el-select
                v-model="annualDataType"
                placeholder="请选择"
                class="annualDataTypeSelect"
              >
                <el-option
                  v-for="item in [
                    { value: 0, label: '成交量' },
                    { value: 1, label: '成交金额' },
                  ]"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>

              <el-date-picker
                v-model="annualDataYear"
                type="year"
                placeholder="选择年"
              >
              </el-date-picker>
            </div>
            <div id="monthlyDataInfo"></div>
            <div class="indexs">
              <div class="divmain">
                <!-- <h3 style="margin: 2% 0 0% 5%">用户新增</h3> -->
                <!-- <div class="pitch"></div> -->
                <div class="filterItem">
                  <!-- <div
                    style="
                      width: 70px;
                      height: 25px;
                      border: 2px solid #58aeff;
                      border-radius: 4px;
                      text-align: center;
                      line-height: 25px;
                      color: #58aeff;
                      margin: 24px;
                    "
                  >
                    总新增
                  </div> -->
                  <el-select
                    v-model="pieChartType"
                    size="mini"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in [
                        { value: 0, label: '近期订单量' },
                        { value: 1, label: '近期商家' },
                      ]"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    v-model="provinceValue"
                    size="mini"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in provinceList"
                      :key="item.provinceCode"
                      :label="item.provinceName"
                      :value="item.provinceName"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="chartsShow">
                  <div style="width: 50%; height: 100%">
                    <div id="last5day"></div>
                  </div>
                  <el-scrollbar class="scrollbar">
                    <div class="cityList">
                      <div
                        v-for="(i, n) in body.userAddition.differentiate"
                        :key="n"
                        style="
                          display: flex;
                          justify-content: left;
                          align-items: center;
                          margin-bottom: 24px;
                        "
                      >
                        <div
                          style="
                            display: flex;
                            justify-content: left;
                            align-items: center;
                            width: 150px;
                          "
                        >
                          <p class="quantity" :style="getCityColor(n)"></p>

                          {{ i.city }}
                        </div>
                        <p style="font-size: 14px; color: #999">
                          {{ i.num }}{{ pieChartType === 0 ? "单" : "家" }}
                        </p>
                      </div>
                    </div>
                  </el-scrollbar>
                </div>
              </div>
              <div class="divmain">
                <h3 style="margin: 2% 0 0% 5%">线上热门搜索</h3>
                <div class="pitch"></div>
                <div class="maindata">
                  <div>
                    <p>搜索用户数</p>
                    <h4>
                      <animate-number
                        ref="Searchnumberofusers"
                        from="0"
                        :to="body.searchUserNum"
                        :key="body.searchUserNum"
                        duration="2000"
                      ></animate-number>
                    </h4>
                  </div>
                  <div>
                    <div>
                      <p>人均搜索次数</p>
                      <h4>
                        <animate-number
                          ref="Searchespercapita"
                          from="0"
                          :to="body.averageSearchNum"
                          :key="body.averageSearchNum"
                          duration="2000"
                        ></animate-number>
                      </h4>
                    </div>
                  </div>
                </div>
                <!-- <div class="hotsearch">
                  <div id="main"></div>
                  <div id="mainb"></div>
                </div> -->
                <el-scrollbar class="rankListScrollbar">
                  <el-table
                    :data="body.rankList"
                    style="width: 90%; margin-left: 5%"
                    ref="singleTable"
                    highlight-current-row
                    row-key="id"
                    stripe
                  >
                    <el-table-column
                      prop="location"
                      label="排名"
                      show-overflow-tooltip
                    >
                      <template slot-scope="scope">
                        {{ scope.$index + 1 }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="keyword"
                      label="搜索关键词"
                      show-overflow-tooltip
                    />
                    <el-table-column
                      label="搜索次数"
                      prop="count"
                      fixed="right"
                    >
                    </el-table-column>
                  </el-table>
                </el-scrollbar>
                <!--分页-->
                <!-- <el-pagination
  
      :page-sizes="[10, ]"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    
    </el-pagination> -->
              </div>
            </div>
          </div>
          <div class="centerRight">
            <el-calendar v-model="calendarValue" class="calendar">
              <template slot="dateCell" slot-scope="{ date, data }">
                <p :class="data.isSelected ? 'is-selected' : ''">
                  {{ Number(data.day.split("-").slice(2).join("")) }}
                  <!-- {{ data.isSelected ? '✔️' : ''}} -->
                </p>
              </template>
            </el-calendar>
            <div class="CommodityRanking">
              <h4>城市销售额排名</h4>
              <el-scrollbar class="cityRankScrollbar">
                <div
                  class="CommodityRankingdata"
                  v-for="(i, n) in cityRankList"
                  :key="n"
                >
                  <div class="ranking">
                    <p
                      :style="
                        n + 1 == 1
                          ? 'background-color:#F2637B;color: #fff;'
                          : n + 1 == 2
                          ? 'background-color:#FAD337;color: #fff;'
                          : n + 1 == 3
                          ? 'background-color:#36CBCB;color: #fff;'
                          : ''
                      "
                      class="rankingquantity"
                    >
                      {{ n + 1 }}
                    </p>
                    <p
                      :style="
                        n + 1 == 1
                          ? 'color: #F2637B;'
                          : n + 1 == 2
                          ? 'color: #FAD337;'
                          : n + 1 == 3
                          ? 'color:#36CBCB;'
                          : ''
                      "
                      class="rankingname"
                    >
                      {{ i.name }}
                    </p>
                  </div>
                  <div class="sales">
                    {{ i.money }}
                  </div>
                </div>
              </el-scrollbar>
            </div>
          </div>
        </div>
        <div class="manageListContent">
          <h3>合伙人管理</h3>
          <el-row :gutter="10">
            <el-col :span="3.3">
              <el-select
                v-model="queryInfo.role"
                :clearable="false"
                placeholder="根据角色查询"
              >
                <el-option
                  v-for="item in [
                    { value: '合伙人', label: '合伙人' },
                    { value: '合伙人经理', label: '合伙人经理' },
                    { value: '总部员工', label: '总部员工' },
                    { value: '商家', label: '商家' },
                  ]"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>

            <!-- <el-col :span="3.3">
              <el-date-picker
                style="width: 150px"
                class="mr15"
                v-model="queryInfo.goodsBeginTime"
                type="date"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="开始时间"
              >
              </el-date-picker>
            </el-col>
            <el-col :span="3.3">
              <el-date-picker
                style="width: 150px"
                v-model="queryInfo.goodsEndingTime"
                type="date"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="结束时间"
              >
              </el-date-picker>
            </el-col> -->
            <el-col :span="3.3">
              <el-date-picker
                v-model="queryInfo.goodsBeginTime"
                type="daterange"
                clearable="true"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-col>
            <el-col :span="3.3">
              <v-distpicker
                size="mini"
                @province="distpickerprovince"
                @city="oncity"
                @area="addressChecked"
                class="v-distpicker"
                :province="queryInfo.province"
                :city="queryInfo.city"
                :area="queryInfo.district"
              ></v-distpicker>
            </el-col>
            <!-- <el-col :span="4">
              <el-button type="primary" @click="toSearchPartnerList"
                >查询</el-button
              >
            </el-col> -->
            <el-col :span="4">
              <div>
                <el-popover placement="top" v-model="popoverVisible">
                  <p>
                    下载 &nbsp;<el-input
                      v-model="startPage"
                      style="width: 60px"
                      placeholder="页数"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      size="mini"
                    ></el-input
                    >&nbsp;至&nbsp;<el-input
                      v-model="endPage"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      placeholder="页数"
                      style="width: 60px"
                      size="mini"
                    ></el-input>
                    &nbsp;页
                  </p>
                  <div style="text-align: right; margin: 10px 0 0 0">
                    <el-button
                      size="mini"
                      type="text"
                      @click="popoverVisible = false"
                      >取消</el-button
                    >
                    <el-button
                      type="primary"
                      size="mini"
                      @click="downloadDataToDesktop"
                      >确定</el-button
                    >
                  </div>
                  <el-button slot="reference" class="download"
                    ><i class="el-icon-download"></i>下载数据至桌面</el-button
                  >
                </el-popover>
                <!-- <div class="download" @click="downloadDataToDesktop">
                  <i class="el-icon-download"></i> 下载数据至桌面
                </div> -->
              </div>
            </el-col>
          </el-row>
          <div class="businessDetailFilter" v-if="queryInfo.role === '商家'">
            <el-select
              v-model="queryInfoExtra.detailed"
              :clearable="false"
              placeholder="查看明细"
              size="mini"
            >
              <el-option
                v-for="item in [
                  { value: '收入明细', label: '收入明细' },
                  { value: '支出明细', label: '支出明细' },
                  { value: '总明细', label: '总明细' },
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <el-table
            ref="showTableData"
            :data="showTableData"
            v-loading="tableLoading"
            border
            class="listTable"
            style="width: calc(100vw - 240px)"
          >
            <el-table-column label="日期" prop="createTime" width="150px">
              <template slot-scope="scope">
                <div>{{ getDateStr(scope.row.createTime) }}</div>
              </template>
            </el-table-column>
            <el-table-column
              :label="queryInfo.role === '商家' ? '店铺名称' : '名字'"
              prop="name"
              width=""
            >
            </el-table-column>
            <el-table-column label="省" prop="province" width="">
            </el-table-column>
            <el-table-column
              v-if="['商家', '总部员工'].includes(queryInfo.role)"
              label="城市"
              prop="city"
              width="150"
            >
            </el-table-column>
            <el-table-column label="区域" prop="district" width="">
            </el-table-column>
            <el-table-column
              label="总分红金额"
              prop="totalCommission "
              width=""
              v-if="
                ['合伙人', '合伙人经理', '总部员工'].includes(queryInfo.role)
              "
            >
              <template slot-scope="scope">
                <div v-if="scope.row.totalCommission">
                  {{ scope.row.totalCommission }} 元
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <!-- <el-table-column
              v-if="queryInfo.role === '商家'"
              label="店铺名称"
              prop="businessName "
              width=""
            >
              <template slot-scope="scope">
                <div v-if="scope.row.businessName">
                  {{ scope.row.businessName }}
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column> -->

            <el-table-column
              v-if="
                queryInfo.role === '商家' &&
                queryInfoExtra.detailed !== '支出明细'
              "
              label="总收入"
              prop="totalIncome "
              width=""
            >
              <template slot-scope="scope">
                <div v-if="scope.row.totalIncome">
                  {{ scope.row.totalIncome }} 元
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="
                !(
                  queryInfo.role === '商家' &&
                  queryInfoExtra.detailed === '收入明细'
                )
              "
              label="总支出"
              prop="totalExpenditure "
              width=""
            >
              <template slot-scope="scope">
                <div v-if="scope.row.totalExpenditure">
                  {{ scope.row.totalExpenditure }} 元
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>

            <el-table-column label="手机号" prop="mobile" width="">
            </el-table-column>
          </el-table>

          <el-pagination
            background
            @size-change="_handleSizeChange"
            @current-change="_handleCurrentChange"
            :current-page="queryInfoExtra.page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="queryInfoExtra.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableOtherInfo.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import loadFile from "../../utils/txt";
import { getDateStr } from "../../utils/getDate";
// import { getOrderData } from "../../api/substitute";
import {
  getAllUserData,
  getOrderData,
  getYearlyOrderData,
  getSearchData,
  searchProvinceList,
  getProductSaleData,
  getPartnerDataInfo,
  getBusinessCountDataByProvince,
  getBusinessOrderCountDataByProvince,
  getInternalEmployeeDataInfo,
  getBusinessIndoDataInfo,
} from "@/api";
import { push } from "echarts/lib/component/dataZoom/history";
export default {
  // props:{
  //   getDateStr:{
  //     type:Function
  //   }
  // },
  data() {
    return {
      startPage: null,
      endPage: null,
      popoverVisible: false,
      provinceValue: "内蒙古自治区",
      provinceList: [],
      tableLoading: true,
      color: [
        "#FF6633",
        "#FFB399",
        "#FF33FF",
        "#FFFF99",
        "#00B3E6",
        "#E6B333",
        "#3366E6",
        "#999966",
        "#99FF99",
        "#B34D4D",
        "#80B300",
        "#809900",
        "#E6B3B3",
        "#6680B3",
        "#66991A",
        "#FF99E6",
        "#CCFF1A",
        "#FF1A66",
        "#E6331A",
        "#33FFCC",
        "#66994D",
        "#B366CC",
        "#4D8000",
        "#B33300",
        "#CC80CC",
        "#66664D",
        "#991AFF",
        "#E666FF",
        "#4DB3FF",
        "#1AB399",
        "#E666B3",
        "#33991A",
        "#CC9999",
        "#B3B31A",
        "#00E680",
        "#4D8066",
        "#809980",
        "#E6FF80",
        "#1AFF33",
        "#999933",
        "#FF3380",
        "#CCCC00",
        "#66E64D",
        "#4D80CC",
        "#9900B3",
        "#E64D66",
        "#4DB380",
        "#FF4D4D",
        "#99E6E6",
        "#6666FF",
      ],
      pieChartType: 0,
      annualDataType: 1,
      annualDataYear: new Date(),
      calendarValue: new Date(),
      Searchespercapita: 0,
      searchTodayregion: [],
      userAdditionquantity: [],
      searchToday: [],
      searchesPerCapita: [],
      body: {
        userAddition: {},
        searchesPerCapita: [],
        Searchnumberofusers: 0,
        PageViewDataInfodata: [],
        PageViewDataInfolist: [],
        proportionAddNewUsers: 0,
        addNewUsers: 0,
        transactionConversionCate: 0,
        todayTransactionConversionCate: 0,
        todayPageView: 0,
        pageView: 0,
        totalVolume: 0,
        todayisTradingVolume: 0,

        todayCount: 0,
        todayVisit: 1000,
        total: 25959,
        visit: 1000,
        orderCount: 0,
        orderMoneyCount: 0,
        todayOrderCount: null,
        todayOrderMoneyCount: 0,
      },
      character: [
        { location: 1, name: "北京烤鸭", sales: "323234" },
        { location: 2, name: "涮羊肉", sales: "323234" },
        { location: 3, name: "炙子烤肉", sales: "323234" },
        { location: 4, name: "炸酱面", sales: "323234" },
      ],
      total: 0,
      date: 0,
      data: [
        { name: "北京烤鸭", sales: "323234" },
        { name: "涮羊肉", sales: "323234" },
        { name: "炙子烤肉", sales: "323234" },
        { name: "炸酱面", sales: "323234" },
        { name: "水爆肚", sales: "323234" },
        { name: "卤煮", sales: "323234" },
        { name: "炒肝", sales: "323234" },
      ],
      left: 0,
      last5daydata: [],
      last5daylist: [],
      last30daydata: [],
      // monthlyDataInfodata: [],
      monthlyDataInfolist: {},
      todayIncome: "",
      todayOrderNumbers: "",
      totalOrderCount: 0,
      userCount: 0,
      last30daylist: [],
      time: null,
      dataValArray: 0,
      backgroundp: 0,
      paye: 666,
      queryInfoExtra: {
        // 属性改变不需要请求接口
        page: 1,
        size: 10,
        detailed: "总明细",
      },
      queryInfo: {
        province: "内蒙古自治区",
        district: "",
        city: "",
        goodsBeginTime: "",
        goodsEndingTime: "",
        role: "合伙人",
        // businessId: 0,
      },
      tableData: [],
      showTableData: [
        {
          city: "内蒙古",
          area: "乌兰浩特",
          name: "富航百悦",
          // totalTurnover: 99999,
          totalIncome: 99999,
          totalExpenditure: 99999,
        },
      ],

      tableOtherInfo: { total: 0 },
      userAdditionregion: [],
      monthlyDataInfodata: [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月",
      ],
    };
  },
  mounted() {
    this.ongetOrderData();
  },
  created() {},
  methods: {
    // objectSpanMethod({ row, column, rowIndex, columnIndex }) {
    //   if (columnIndex === 0 && this.queryInfo.role === "商家") {
    //     if (rowIndex % 10 === 0) {
    //       return {
    //         rowspan: 10,
    //         colspan: 1,
    //       };
    //     } else {
    //       return {
    //         rowspan: 0,
    //         colspan: 0,
    //       };
    //     }
    //   }
    // },
    downloadDataToDesktop() {
      const initialData = [
        "日期",
        "名字",
        "省",
        "城市",
        "区域",
        "总分红金额",
        "店铺名",
        "总营业额",
        "总收入",
        "总支出",
        "手机号",
      ];
      let tableDatas = [];
      const _this = this;

      // this.tableData = updateData;
      if (this.startPage < 1) return;
      if (this.endPage < this.startPage) {
        this.$message.error("截至页需大于开始页");
        return;
      }
      const downloadData = this.tableData.slice(
        (this.startPage - 1) * this.queryInfoExtra.size,
        this.endPage * this.queryInfoExtra.size
      );

      const initialDatas = () => {
        if (_this.queryInfo.role !== "商家") {
          if (_this.queryInfo.role === "总部员工") {
            tableDatas = downloadData.map((item) => {
              const items = { ...item };
              // delete items.businessName;
              // delete items.totalTurnover;
              // delete items.totalCommission;
              delete items.totalIncome;
              // delete items.totalExpenditure;
              return items;
            });
            return [
              [
                "日期",
                "名字",
                "省",
                "城市",
                "区域",
                "总分红金额",
                "总支出",
                "手机号",
              ],
            ];
          } else {
            tableDatas = downloadData.map((item) => {
              const items = { ...item };
              // delete items.businessName;
              // delete items.totalTurnover;
              delete items.totalIncome;
              // delete items.totalExpenditure;
              return items;
            });
            return [
              [
                "日期",
                "名字",
                "省",
                "城市",
                "区域",
                "总分红金额",
                // "总收入",
                "总支出",
                "手机号",
              ],
            ];
          }
        } else if (
          _this.queryInfo.role === "商家" &&
          _this.queryInfoExtra.detailed === "支出明细"
        ) {
          tableDatas = downloadData.map((item) => {
            const items = { ...item };
            // delete items.totalTurnover;
            delete items.totalIncome;
            delete items.totalCommission;
            return items;
          });
          return [
            ["日期", "店铺名称", "省", "城市", "区域", "总支出", "手机号"],
          ];
        } else if (
          _this.queryInfo.role === "商家" &&
          _this.queryInfoExtra.detailed === "收入明细"
        ) {
          tableDatas = downloadData.map((item) => {
            const items = { ...item };
            // delete items.totalTurnover;
            delete items.totalExpenditure;
            delete items.totalCommission;

            return items;
          });
          return [
            ["日期", "店铺名称", "省", "城市", "区域", "总收入", "手机号"],
          ];
        } else if (
          _this.queryInfo.role === "商家" &&
          _this.queryInfoExtra.detailed === "总明细"
        ) {
          tableDatas = downloadData.map((item) => {
            const items = { ...item };
            delete items.totalCommission;

            return items;
          });
          return [
            [
              "日期",
              "店铺名称",
              "省",
              "城市",
              "区域",
              "总收入",
              "总支出",
              "手机号",
            ],
          ];
        }
      };
      const _data = initialDatas();
      tableDatas.forEach((element, i) => {
        const elementData = Object.values(element);
        // 将时间戳转化为日期
        elementData[0] = getDateStr(elementData[0]);
        console.log(elementData, "elementDataelementDataelementData");

        _data.push(elementData);
      });

      const ws = _this.$XLSX.utils.aoa_to_sheet(_data);

      const wb = _this.$XLSX.utils.book_new();
      const textName =
        this.queryInfo.role === "商家"
          ? `富航${this.queryInfo.role}${this.queryInfoExtra.detailed}`
          : `富航${this.queryInfo.role}总明细`;
      _this.$XLSX.utils.book_append_sheet(wb, ws, textName);

      /* save to file */
      _this.$XLSX.writeFile(wb, `${textName}.xlsx`);
      this.popoverVisible = false;
    },
    getCityColor(n) {
      return `background-color:${this.color[n]}`;
    },
    // 地区筛选
    addressChecked(data) {
      this.queryInfo.district = data.value;
      if (data.value === "区") {
        this.queryInfo.district = "";
      }
    },
    // 选择市
    oncity(data) {
      this.queryInfo.city = data.value;
      this.queryInfo.district = "";
      if (data.value === "市") {
        this.queryInfo.city = "";
      }
    },
    // 省级筛选
    distpickerprovince(data) {
      this.queryInfo.province = data.value;
      this.queryInfo.city = "";
      this.queryInfo.district = "";
      if (data.value === "省") {
        this.queryInfo.province = "";
      }
    },
    // toSearchPartnerList() {
    //   this.getLiveList(1, 10);
    // },
    getDateStr(tiemstamp) {
      return getDateStr(tiemstamp);
    },
    async getLiveList(page, size) {
      this.tableLoading = true;
      const startTime = this.queryInfo.goodsBeginTime?.[0]
        ? new Date(this.queryInfo.goodsBeginTime[0]).getTime()
        : null;
      const endTime = this.queryInfo.goodsBeginTime?.[1]
        ? new Date(this.queryInfo.goodsBeginTime[1]).getTime()
        : null;

      const queryData = { ...this.queryInfo, startTime, endTime };
      if (queryData.province === "") {
        delete queryData.province;
      }
      if (queryData.city === "") {
        delete queryData.city;
      }
      if (queryData.district === "") {
        delete queryData.district;
      }
      delete queryData.detailed;
      delete queryData.goodsEndingTime;
      delete queryData.goodsBeginTime;
      delete queryData.size;
      queryData.page = 1;
      const queryListWay = ["合伙人", "合伙人经理"].includes(
        this.queryInfo.role
      )
        ? getPartnerDataInfo
        : this.queryInfo.role === "总部员工"
        ? getInternalEmployeeDataInfo
        : getBusinessIndoDataInfo;
      const { data: res } = await queryListWay(queryData);

      const updateData = res.body.map(
        (
          {
            createTime,
            name,
            province,
            city,
            district,
            totalCommission,
            businessName,
            // totalTurnover,
            totalIncome,
            totalWithdraw,
            mobile,
            businessPhone,
            internalEmployeeManagerArea,
          },
          index
        ) => {
          const items = {
            createTime,
            name: name || businessName,
            province: province || internalEmployeeManagerArea?.[0]?.province,
            city: city || internalEmployeeManagerArea?.[0]?.city,
            district: district || internalEmployeeManagerArea?.[0]?.district,
            totalCommission,
            // totalTurnover,
            totalIncome,
            totalExpenditure: totalWithdraw,
            mobile: mobile || businessPhone,
          };
          return items;
        }
      );

      this.tableData = updateData;
      this.showTableData = updateData.slice((page - 1) * size, page * size);
      // res.body.list.slice(0, 10);
      this.tableOtherInfo.total = updateData.length;
      this.tableLoading = false;
    },
    _handleSizeChange(newSize) {
      this.queryInfoExtra.size = newSize;
      this.showTableData = this.tableData.slice(0, newSize);
    },

    _handleCurrentChange(page) {
      this.queryInfoExtra.page = page;
      this.showTableData = this.tableData.slice(
        (page - 1) * this.queryInfoExtra.size,
        page * this.queryInfoExtra.size
      );
      console.log("1111", this.showTableData);
    },

    onindex(e) {
      this.left = e;
      if (!e) {
        this.monthlyDataInfoChart(
          this.monthlyDataInfodata,
          this.monthlyDataInfolist
        );
      } else {
        this.monthlyDataInfoChart(
          this.PageViewDataInfodata,
          this.PageViewDataInfolist
        );
      }
    },
    // 点击切换日期
    ondat(e) {
      this.backgroundp = e;
    },

    async searchOrderOrBusinessNum(type, province) {
      var searchTodayregion = [];
      var userAdditionquantity = [];

      const getBusinessCountDataWay =
        type === 0
          ? getBusinessOrderCountDataByProvince
          : getBusinessCountDataByProvince;
      const {
        data: { body: getBusinessCountData },
      } = await getBusinessCountDataWay({
        province,
      });
      this.body.userAddition.differentiate = getBusinessCountData;

      getBusinessCountData.forEach((element) => {
        searchTodayregion.push(element.city);
        userAdditionquantity.push(element.num);
      });
      this.userAdditionregion = searchTodayregion;

      this.userAdditionquantity = userAdditionquantity;
    },

    async ongetOrderData() {
      var res = {
        body: {
          cityRank: undefined,
          userAddition: { differentiate: undefined },
        },
      };
      this.getLiveList(1, 10);
      this.searchOrderOrBusinessNum(0, "内蒙古");

      this.body = res.body;
      const {
        data: { body: provinceData },
      } = await searchProvinceList();
      this.provinceList = provinceData;
      console.log(provinceData, "provinceData");

      const calendarVal = new Date().getTime();
      const {
        data: { body },
      } = await getProductSaleData({ date: calendarVal });
      this.body.cityRank = body;
      const {
        data: {
          body: { averageSearchNum, rankList, searchUserNum },
        },
      } = await getSearchData();

      this.body = Object.assign(res.body, {
        averageSearchNum,
        rankList,
        searchUserNum,
      });

      const {
        data: { body: getAllUserDataValue },
      } = await getAllUserData();
      const {
        data: { body: getOrderDataValue },
      } = await getOrderData();

      this.body = {
        ...this.body,
        ...getAllUserDataValue,
        ...getOrderDataValue,
      };

      this.$nextTick(() => {
        this.$refs.reNum.start();
        this.$refs.totalOrderCountNum.start();
        this.$refs.todayisTradingVolume.start();
        this.$refs.todayPageView.start();
        this.$refs.transactionConversionCate.start();
        this.$refs.todayTransactionConversionCate.start();
        this.$refs.addNewUsers.start();
        this.$refs.proportionAddNewUsers.start();
        this.$refs.Searchnumberofusers.start();
        this.$refs.Searchespercapita.start();
      });
      this.drawChart();
      this.drawChartb();

      this.barGraph();
    },
    barGraph(val) {
      var _this = this;
      //初始化图标
      var myCharts = this.$echarts.init(document.getElementById("last5day"));

      let totalValue = 0;
      const userAdditionregionArray =
        this.userAdditionregion.length > 0 ? this.userAdditionregion : ["未知"];
      const dataArray = userAdditionregionArray.map((item, index) => {
        const items = {
          dataname: item,
          value: this.userAdditionquantity[index] || 0,
          itemStyle: {
            normal: {
              color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
                {
                  offset: 0,
                  color: this.color[index],
                },
              ]),
            },
          },
        };
        totalValue += this.userAdditionquantity[index] || 0;
        return items;
      });
      //Y轴的数据，和数据值位置一一对应
      //数据值，顺序和Y轴的名字一一对应totalCount
      const setText = this.pieChartType === 0 ? "新增订单" : "新增商家";
      var option = {
        title: [
          {
            text: setText,
            textStyle: {
              color: "#909090",
              fontSize: 12,
            },
            subtext: totalValue + `${this.pieChartType === 0 ? "单" : "家"}`,
            subtextStyle: {
              color: "#000",
              fontSize: 25,
            },
            itemGap: 20, // 主副标题距离
            left: "center",
            top: "center",
          },
        ],
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            return (
              '<div class="chartLabel">' +
              "<div class=title>" +
              params.data.dataname +
              " : " +
              params.data.value +
              "</div>" +
              "</div>"
            );
          },
        },
        legend: {
          top: "5%",
          left: "center",
        },
        grid: {
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          containLabel: true,
        },

        series: [
          {
            type: "pie",
            radius: ["70%", "90%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },

            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: dataArray,
          },
        ],
      };
      myCharts.setOption(option, true);
      //图表大小变动从新渲染，动态自适应
      window.addEventListener("resize", function () {
        myCharts.resize();
      });
    },
    drawChart() {
      var _this = this;
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById("main"));
      // 指定图表的配置项和数据
      let option = {
        color: ["#79CBEB"],
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          show: false,
          data: ["Line 1", "Line 2", "Line 3", "Line 4", "Line 5"],
        },

        grid: {
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          containLabel: true,
        },
        xAxis: [
          {
            show: false,
            type: "category",
            boundaryGap: false,
            data: [],
          },
        ],
        yAxis: [
          {
            show: false,
            type: "value",
          },
        ],
        series: [
          {
            name: "Line 1",
            type: "line",
            stack: "Total",
            smooth: true,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(128, 255, 165)",
                },
                {
                  offset: 1,
                  color: "rgb(1, 191, 236)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: this.searchToday,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    drawChartb() {
      var _this = this;
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById("mainb"));
      // 指定图表的配置项和数据
      let option = {
        color: ["#79CBEB"],
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          show: false,
          data: ["Line 1", "Line 2", "Line 3", "Line 4", "Line 5"],
        },

        grid: {
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          containLabel: true,
        },
        xAxis: [
          {
            show: false,
            type: "category",
            boundaryGap: false,
            data: [],
          },
        ],
        yAxis: [
          {
            show: false,
            type: "value",
          },
        ],
        series: [
          {
            name: "Line 1",
            type: "line",
            stack: "Total",
            smooth: true,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(128, 255, 165)",
                },
                {
                  offset: 1,
                  color: "rgb(1, 191, 236)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: this.searchesPerCapita,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    // 订单数量趋势柱状图
    monthlyDataInfoChart(i, n) {
      var _this = this;
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChartr = this.$echarts.init(
        document.getElementById("monthlyDataInfo")
      );
      console.log(
        this.annualDataType,
        typeof this.annualDataType,
        "this.annualDataType"
      );
      const series =
        this.annualDataType === 1
          ? [
              {
                type: "line",
                name: "成交金额",
                data: n.totalTradeMoney,
                smooth: true,
                areaStyle: {
                  color: "#0073FE",
                  opacity: 0.5,
                },
                itemStyle: {
                  // 设置线条上点的颜色（和图例的颜色）
                  normal: {
                    color: "#0073FE",
                  },
                },
              },
            ]
          : [
              {
                type: "line",
                smooth: true,
                name: "成交量",
                data: n.totalOrderNumber,
                areaStyle: {
                  color: "#0073FE",
                  opacity: 0.5,
                },
                itemStyle: {
                  // 设置线条上点的颜色（和图例的颜色）
                  normal: {
                    color: "#0073FE",
                  },
                },
              },
            ];
      // 指定图表的配置项和数据
      let option = {
        // legend: {
        //   data: ["总流水(千元)", "总单量(千单)", "利润(千元)"],
        // },
        // title: {
        //   text: "全年一览",
        // },
        title: [
          {
            text: "全年一览",
            textStyle: {
              // color: "#39FAD8",
              // fontSize: 40,
              // marginLeft: 15,
            },

            left: 15,
            // top: "center",
          },
        ],
        tooltip: {
          trigger: "axis",
        },

        grid: {
          left: "3%",
          right: "4%",

          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          axisLine: {
            show: false, // 不显示坐标轴线
          },
          axisTick: {
            show: false, // 不显示坐标轴刻度线
          },
          show: true,
          type: "category",
          // boundaryGap: false,
          data: i,
          axisLabel: {
            show: true,
            inside: false,
            color: "#999",
            interval: 0,
          },
        },
        yAxis: {
          axisTick: {
            show: false, // 不显示坐标轴刻度线
          },

          axisLine: {
            show: false, // 不显示坐标轴线
          },
          type: "value",
          axisLabel: {
            show: true,
            inside: false,
            color: "#999",
            formatter: "{value} ",
          },
        },

        series,
      };

      // 使用刚指定的配置项和数据显示图表。
      myChartr.setOption(option);
      window.addEventListener("resize", function () {
        myChartr.resize();
      });
    },
    drawCharts() {
      // 基于准备好的dom，初始化echarts实例
      var myChartop = this.$echarts.init(document.getElementById("fourth"));
      this.dataValArray = this.dataValArray + 10;
      if (this.dataValArray == 100) {
        this.dataValArray = 80;
      }
      let option = {
        title: [
          {
            text: `${this.dataValArray}%`,
            textStyle: {
              color: "#39FAD8",
              fontSize: 40,
            },
            subtext: "完成率",
            subtextStyle: {
              color: "#909090",
            },
            itemGap: -10, // 主副标题距离
            left: "center",
            top: "center",
          },
        ],
        angleAxis: {
          max: 100,
          clockwise: true, // 逆时针
          // 隐藏刻度线
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        radiusAxis: {
          type: "category",
          // 隐藏刻度线
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        polar: {
          center: ["50%", "50%"],
          radius: "170%", //图形大小
        },
        series: [
          {
            type: "bar",
            data: [
              {
                name: "完成率",
                value: this.dataValArray,
                itemStyle: {
                  normal: {
                    color: new this.$echarts.graphic.LinearGradient(
                      1,
                      0,
                      0,
                      0,
                      [
                        {
                          offset: 0,
                          color: "#459EF9",
                        },
                        {
                          offset: 1,
                          color: "#39FAD8",
                        },
                      ]
                    ),
                  },
                },
              },
            ],
            coordinateSystem: "polar",
            roundCap: true,
            barWidth: 25,
            barGap: "-100%", // 两环重叠
            z: 2,
          },
          {
            // 灰色环
            type: "bar",
            data: [
              {
                value: 100,
                itemStyle: {
                  color: "#EEF2F3",
                  shadowColor: "rgba(0, 0, 0, 0.2)",
                },
              },
            ],
            coordinateSystem: "polar",
            roundCap: true,
            barWidth: 25,
            barGap: "-100%", // 两环重叠
            z: 1,
          },
        ],
      };

      myChartop.setOption(option);
      window.addEventListener("resize", function () {
        myChartop.resize();
      });
    },

    async annualDataWay(type, date) {
      const year = new Date(date).getFullYear(); // 获取当前年份
      const startOfYear = new Date(year, 0, 1).getTime(); // 获取当前年份的开始时间戳
      console.log(date, "console.log(this.annualDataYear)", year);

      const {
        data: { body: getYearlyOrderDataValue },
      } = await getYearlyOrderData({
        type: `${type}`,
        date: `${startOfYear}`,
      });
      this.monthlyDataInfolist.totalOrderNumber = Object.values(
        getYearlyOrderDataValue
      );
      this.monthlyDataInfolist.totalTradeMoney = Object.values(
        getYearlyOrderDataValue
      );
      this.monthlyDataInfoChart(
        this.monthlyDataInfodata,
        this.monthlyDataInfolist
      );
    },
  },
  watch: {
    calendarValue: {
      async handler(newVal, oldVal) {
        const calendarVal = new Date(newVal).getTime();
        const {
          data: { body },
        } = await getProductSaleData({ date: calendarVal });
        this.body.cityRank = body;
        console.log(newVal, "newVal", body);
      },
      immediate: true, //初始值也要监听
      // deep:true       // 深度观察监听
    },
    value2: {
      handler(newVal, oldVal) {
        console.log(newVal, "value2");
      },
    },
    annualDataType: {
      async handler(newVal, oldVal) {
        this.annualDataWay(newVal, this.annualDataYear);
      },
      immediate: true, //初始值也要监听
    },
    annualDataYear: {
      handler(newVal, oldVal) {
        this.annualDataWay(this.annualDataType, newVal);
      },
      immediate: true,
    },
    pieChartType: {
      async handler(newVal, oldVal) {
        await this.searchOrderOrBusinessNum(newVal, this.provinceValue);
        this.barGraph();
      },
      immediate: true,
    },
    provinceValue: {
      async handler(newVal, oldVal) {
        await this.searchOrderOrBusinessNum(this.pieChartType, newVal);
        this.barGraph();
      },
      immediate: true,
    },
    queryInfo: {
      handler(newVal, oldVal) {
        console.log("是否显示清除按钮", newVal, oldVal);

        this.$nextTick(() => {
          this.getLiveList(1, 10);
        });
      },
      deep: true,
      // immediate: true,
    },
  },
  computed: {
    cityRankList() {
      return this.body.cityRank?.slice().reverse();
    },
  },
};
</script>
<style scoped>
#last5day {
  height: 300px;
}
.mainPage {
  /* background: #f1f5f8; */
}
.manageListContent {
  padding-bottom: 20px;
}
.manageListContent .download {
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  background: #fff;
  padding: 5px;
  width: 50%;
  margin-left: 40%;
  font-weight: bold;
}
.manageListContent .download .el-icon-download {
  font-weight: bold !important;
}
.maindata div h4 {
  font-size: 24px;
  margin: 24px;
}
.maindata div p {
  font-size: 14px;
  color: #999;
  margin: 24px;
}
.maindata div {
  width: 50%;
}
.maindata {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.hotsearch div {
  width: 49%;
  height: 100px;
}
.hotsearch {
  width: 100%;
  display: flex;
  justify-content: center;
}
.sales {
  font-size: 14px;
  color: #999;
}
.rankingquantity {
  width: 20px;
  height: 20px;
  border-radius: 90px;
  background-color: #f0f2f5;
  text-align: center;
  line-height: 20px;
  margin-right: 12px;
}
.ranking {
  display: flex;
  justify-content: left;
  align-items: center;
}
.CommodityRankingdata {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.CommodityRanking {
  /* width: 300px; */
  height: 100%;
  margin-right: 24px;
  margin-left: 25px;
}
.pitch div {
  width: 48px;
  height: 100%;
  background-color: #78b4e4 !important;
  position: absolute;
  top: 0;

  transition: all 0.5s ease;
}
.pitch {
  width: calc(100% - 48px);
  height: 3px;
  margin: 8px 0 8px 24px;
  background-color: #f2f2f2 !important;
  position: relative;
  border-radius: 90px;
}
.dis {
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.indexytite {
  width: 50%;
  display: flex;
  justify-content: left;
  margin-top: 12px;
}
.indexytite div p {
  margin-right: 12px;
}
.indexytite p {
  margin-left: 24px;
}
.dis {
  display: flex;
  justify-content: space-between;
}
.divbottom {
  font-size: 12px;
  margin-left: 10%;
  color: #9999;
  padding-top: 6px;
}
.indexyone {
  display: flex;
  justify-content: space-between;
}
.indexyone .centerLeft {
  width: 66%;
  position: relative;
}
.indexyone .centerLeft .filterItems {
  position: absolute;
  right: 20px;
  top: 15px;
  z-index: 30;
}
.indexyone .centerLeft .filterItems .annualDataTypeSelect {
  margin-right: 10px;
}
.indexyone .centerRight {
  width: 32%;
  background: #fff;
}
.indexyone .centerRight .calendar {
  height: 400px;
}
.indexyone .centerRight .is-selected {
  color: #1989fa;
}
.divdata {
  width: 80%;
  font-size: 18px;
  /* height: 40px;
  line-height: 40px; */
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 8px;
  margin-bottom: 8px;
  margin-left: 6%;
  font-weight: bold;
  /* border-bottom: 1px solid #e9e9e9; */
}
.divtite {
  font-size: 14px;
  color: #aebac6;
  margin-top: 24px;
  font-family: STSong;
  padding-left: 6%;
}
.divSubtite {
  font-size: 14px;
  color: #aebac6;
  font-family: STSong;
  padding-left: 6%;
}
.paye {
  text-align: center;
  margin-top: 24px;
  font-size: 14px;
  font-weight: bold;
}
.pdate {
  width: 100% !important;
  height: 24px !important;

  font-size: 12px;
  line-height: 24px;
  margin-bottom: 0;
  overflow: hidden;
  padding: 12px 0;
}
.pdate p {
  padding: 0 12px;
  border-radius: 90px;
  float: left;
  font-weight: bold;
  cursor: pointer;
}
.backgroundp {
  color: #ca9669;
  background: #fceee2;
}
.index .div {
  width: 23%;

  background: #fff;
  height: 120px;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  border-radius: 4px;
}

.index div img {
  width: 50px;
  height: 50px;
  padding-left: 12px;
  padding-top: 24px;
}
.index div div p {
}
.index div div {
  float: left;
  width: 100%;
  height: 100%;
}

.indexs .divmain {
  width: 49%;
  background: #fff;
  height: 400px;
  border-radius: 4px;
  margin: 10px 20px 0 0;
}
.indexs .divmain .filterItem {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  margin-top: 15px;
}
.indexs .divmain .chartsShow {
  display: flex;
  /* justify-content: space-between; */
}
.indexs .divmain .cityList {
  /* height: 300px; */
  cursor: pointer;
  margin-top: 20px;
  margin-left: 35px;
  /* width: 80%; */
}
.indexs .divmain .scrollbar {
  height: 300px;
  box-sizing: border-box; /* 确保padding和border不会增加元素的宽度 */
  padding: 0;
  margin: 0;
  border: none;
  overflow-x: hidden;
}

#monthlyDataInfo {
  /* margin-left: 20px; */
  width: 100%;
  height: 480px;
  padding-top: 24px;
  background: #fff;
}
.indexy div {
  /* background: #fff; */

  border-radius: 4px;
}
.quantity {
  width: 8px;
  height: 8px;
  border-radius: 90px;
  margin-right: 12px;
}
.indexy {
  width: 100%;
  margin-top: 12px;

  /* background: #fff; */
}
.indexs {
  width: 100%;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}
.index {
  display: flex;
  justify-content: space-between;
}
.el-card {
  border: 0px solid #ebeef5;
  min-width: 780px;
  padding: 0px;
  background: #e7e7ee;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}
.el-card /deep/ .el-card__body {
  padding: 0;
}
.listTable {
  margin-top: 15px;
  padding-left: 5px;
}
.businessDetailFilter {
  margin-top: 15px;
  margin-bottom: -16px;
  height: 30px;
  background: #fff;
  width: 100%;
  padding: 15px 0;
  padding-left: 5px;
}
</style>
<style>
.indexyone .centerRight .calendar .el-calendar-table .el-calendar-day {
  height: 45px !important;
}
.indexs .divmain .scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
.indexs .divmain .scrollbar .is-vertical {
  right: -7px;
}
.CommodityRanking .cityRankScrollbar {
  height: calc(100% - 450px);
  margin-right: 10px;
}
.CommodityRanking .cityRankScrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
.CommodityRanking .cityRankScrollbar .is-vertical {
  right: -7px;
}

.divmain .rankListScrollbar {
  height: 220px;
}
.divmain .rankListScrollbar .is-vertical {
  right: -7px;
}
.divmain .rankListScrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
